import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TextField,
    CircularProgress,
    IconButton,
    Paper,
    Select,
    MenuItem,
    TableContainer
} from "@material-ui/core";
import moment from 'moment';
import PageTitle from "../../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { getEmployees } from '../EmployeeApi'
import { getConfigs } from '../ConfigApi'
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from "react-router-dom";
import StatusBullet from "../../../components/StatusBullet/StatusBullet";
import { deleteEmployee, updateEmployeePassword, updateEmployeeStatus } from '../../../utils/RestApi'
import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import { toast } from 'react-toastify'
import { Box, Button } from 'gestalt'

const EmployeeList = (props) => {
    const { t } = useTranslation();
    const [search_value, setSearchValue] = useState('');
    const [employees, setEmployees] = useState([])
    const [certificates, setCertificates] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [role, setRole] = useState("Бүгд")
    const history = useHistory();
    const { btnColor = "secondary" } = props

    useEffect(() => {
        fetchCertificates()
    }, [])

    useEffect(() => {
        fetchEmployees()
    }, [page])

    useEffect(() => {
        if(page == 1) return fetchEmployees()
        setPage(1)
    }, [search_value, role])

    const fetchEmployees = () => {
        setFetching(true)
        getEmployees(moment().toDate(), {
            page,
            searchRole: role == "Бүгд" ? "" : role,
            search_value
        })
        .then((res) => {
            if (res.data.code == 0) {
                setEmployees(res.data.result.docs) //users
                setPages(res.data.result.pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const fetchCertificates = () => {
        getConfigs({})
        .then((res) => {
            if(res.data.code == 0) {
                res.data.config.map((config, index) => {
                    if(config.field === "Certificate")
                        setCertificates(config.value)
                })
            }
        })
        .catch((err) => alert(err))
    }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onStateChange = (user) => {
        let confirmed = window.confirm(`${user.status == "active" ? "Идэвхгүй" : "Идэвхтэй"} төлөвт оруулахдаа итгэлтэй байна уу`);

        if(confirmed) {
            updateEmployeeStatus(user._id)
            .then(res => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }

                if(res.data.code == 0) {
                    let newEmployees = [...employees]
                    let index = newEmployees.findIndex((current) => String(current._id) == String(user._id))
                    if(index != -1) {
                        newEmployees[index].status = res.data.user.status
                        setEmployees(newEmployees)
                    }

                    return toast.success(t("message.success.updated"))
                }

            })
        }
    }

    const onSetPassword = (user_id) => {
        let password = prompt(t("action.password"));

        if(password) {
            //setFetching(true)
            updateEmployeePassword(user_id, {
                password
            })
            .then(res => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }
                if(res.data.code == 0) {
                    //toast.mess(t("message.success.updated"))
                    toast.success(t("message.success.updated"))
                    //fetchEmployees()
                }
            })
            // .catch(err => alert(err))
            // .then(() => setFetching(false))
        }
        //alert(password)
        // if (password == "scorpio") {
        // alert("Wow! I'm a Scorpio too!");
        // }
    }

    const onEdit = (id) => {
        history.push(`/app/employee/${id}`)
    }

    const onDelete = (id) => {
        const is_confirm = window.confirm(t('delete'))

        if(is_confirm){
            setFetching(true)
            deleteEmployee(id)
            .then(res => {
                if(res.data.code == 0) {
                    toast.success(t("message.success.deleted"))
                    fetchEmployees()
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        }
    }

    const checkHasCertificate = (certificate, certificates) => {
        let index = certificates.findIndex((current) => current.title == certificate.title)

        return index == -1 ? '-' : certificates[index].infinity ? t("certificate.yes") : moment(certificates[index].expired_at).format("YYYY-MM-DD")
    }

    const statusDetecter = (certificate, certificates) => {
        let color;
        let index = certificates.findIndex((current) => current.title == certificate.title);

        if(index != -1){
            if(!certificates[index].infinity){

                let calculate = moment(certificates[index].expired_at).diff(moment(), 'days');

                if(calculate < 0) 
                    color = "danger";
                else if(calculate <= 30) 
                    color = "warning";
                else color = "success"

                return (
                    <StatusBullet 
                        style={{marginRight: 15}}
                        color={color}
                    />
                )
            }
        }
    }

    const onCreateNewEmployee = () => {
        history.push('/app/employee/create')
    }

    const rView = () => {
        return (
            <div>
                <Button variant="contained" color={btnColor} size='large' onClick={() => history.push('/app/employee/create')}>
                    {t("registration.employee.new_employee")}
                </Button>
            </div>
        )
    }

    return (    
        <Box paddingX={10}>
            <PageTitle
                title={t("registration.employee.title")}
                // rightView={() => rView()}
                //btnColor="primary"
                button={t("registration.employee.new_employee")}
                onButtonClick={onCreateNewEmployee}  
            />
            <div style={{ marginBottom: 20 }}>
                <Box display='flex' alignItems='center'>
                    <Box flex="grow">
                        <TextField
                            id="outlined-basic"
                            autoComplete="new-password"
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            label={t("registration.employee.search")}
                            variant="outlined"
                            value={search_value}
                            onChange={(e) => setSearchValue(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box width={20} />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        value={role}
                        variant="outlined"
                        label="Албан тушаал"
                        onChange={(e) => setRole(e.target.value)}
                    >
                        {
                            [{
                                title: "Бүгд"
                            }, {
                                title: "Цагийн ажилтан"
                            }, {
                                title: "Түгээгч"
                            }, {
                                title: "Жолооч"
                            }, {
                                title: "Ээлжийн ахлагч"
                            }, {
                                title: "Оператор"
                            }].map((item, index) => (
                                <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                            ))
                        }
                    </Select>
                </Box>
                
            </div>
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>{t('user.last_name')}</TableCell>
                            <TableCell>{t('user.first_name')}</TableCell>
                            <TableCell>Утас</TableCell>
                            <TableCell>{t('user.role')}</TableCell>
                            <TableCell>Механик барьдаг эсэх</TableCell>
                            <TableCell>Жолооч болсон он</TableCell>
                            <TableCell colSpan={3} align='center' style={{ position: "sticky", right: 0, background: "white" }}>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employees.map((employee, index) => { 
                                return (
                                    <TableRow key={index} hover style={{
                                        background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                    }}>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.sap}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.last_name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.first_name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.phones}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.roles.length > 0 && employee.roles[0].title}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.manual_wheel_option ? "Тийм" : "Үгүй"}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.driver_licence_date ? moment(employee.driver_licence_date).format("YYYY") : "-"}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onEdit(employee._id)}>
                                                <EditIcon color='gray' />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onSetPassword(employee._id)}>
                                                <LockIcon color='gray' />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <Button 
                                                size='sm'
                                                text={employee.status == "active" ? "Идэвхтэй" : "Идэвхгүй"}
                                                color={employee.status == "active" ? "blue" : "gray"}
                                                onClick={() => onStateChange(employee)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
        </Box>
    )
}

export default EmployeeList